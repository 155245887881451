<template>
    <b-card no-body class="card-apply-job">
        <b-card-header class="pb-1" style="height: 100px;">
            <b-media no-body>
                <a @click="detail(item)">
                <b-avatar size="40px" variant="light-primary" rounded="" v-if="item.status_klp =='Aktif'"
                      v-b-modal.modal-1 >
                    <feather-icon icon="EyeIcon" size="15" />
                </b-avatar>
            </a>
            <a @click="detail(item)">
                <b-avatar size="40px" variant="light-danger" rounded="" v-if="item.status_klp !='Aktif'"
                  v-b-modal.modal-1 @click="detail(item)">
                    <feather-icon icon="EyeIcon" size="15" />
                </b-avatar>
            </a> <a @click="detail(item)">
                <b-media-body class="ml-1"   v-b-modal.modal-1>
                    <h5 class="mb-0">
                        {{ item. nama_kelompok}}
                    </h5>
                    <small class="text-muted">{{ item.nama_bidang }}</small>
                </b-media-body>
            </a>
            </b-media>

        </b-card-header>
        <b-card-body>
            <!-- payment  -->
            <div class="apply-job-package bg-light-primary rounded">
                <div class="text-primary" style="margin-top: -30px;;">
                    <small>
                        <b> <br>Durasi kerja
                            {{ item.durasi_kerja }} Jam</b><br>
                        <small>
                            <b-avatar size="20px" variant="light-primary" rounded="">
                                <feather-icon icon="ClockIcon" size="15" />

                            </b-avatar> {{ item.jam_masuk }} - {{ item.jam_pulang }}
                        </small><br>
                        <b>Kordinator</b><br>
                        <b-avatar size="20px" variant="primary" rounded="">
                            <feather-icon icon="UserIcon" size="15" />

                        </b-avatar> {{ item.nm_kordinator }}<br>
                        <b-avatar size="20px" variant="primary" rounded="">
                            <feather-icon icon="PhoneIcon" size="15" /> 
                        </b-avatar> {{ item.no_hp }} <br>
                        <!-- <b class="text-danger" v-show=" item.status_klp !='Aktif'"> Status kelompok
                            {{ item.status_klp }}</b> -->
                    </small>
                </div>
            </div>

            <!--/ payment  -->
            <router-link :to="'/kelompok_kordinat/'+ item.id_kelompok">
                <b-button   class="mb-1" block variant="primary">
                    <feather-icon icon="MapPinIcon" size="20" /> <span
                        class="ml-1 mb-1"><b>{{parseInt(item.jm_lokasi_aktif) + parseInt(item.jm_lokasi_non)}}</b> Titik
                        Koordinat</span>
                </b-button>

            </router-link>
            <router-link :to="'/kelompok_anggota/'+  item.id_kelompok">
                <b-button   class="mb-1" block variant="warning" >
                    <feather-icon icon="UserIcon" size="20" /> <span class="ml-1 mb-1"><b>{{ item.jm_angota }}</b>
                        Anggota Aktif</span>
                </b-button>
            </router-link>
            <b-row>
                <b-col md="6">
                    <b-button   block variant="outline-warning" v-b-modal.editKelompok  @click="loadEdit(item)">
                        <feather-icon icon="EditIcon" size="15" /> Edit
                    </b-button>
                </b-col>
                <b-col md="6">
                    <b-button   block variant="outline-danger"
                        v-if="item.status_klp !='Aktif'" @click="status_kelompok(item)">
                        <feather-icon icon="XCircleIcon" size="15" /> Non
                    </b-button>
                    <b-button   block variant="outline-primary"
                        v-if="item.status_klp =='Aktif'" @click="status_kelompok(item)">
                        <feather-icon icon="CheckCircleIcon" size="15" /> Aktif
                    </b-button>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
    import {
        BCol,
        BRow,
        BCard,
        BCardHeader,
        BMedia,
        BMediaAside,
        BAvatar,
        BMediaBody,
        BBadge,
        BCardText,
        BCardBody,
        BButton,
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'

    export default {
        components: {
            BCard,
            BCol,
            BRow,
            BButton,
            BBadge,
            BCardHeader,
            BMedia,
            BMediaAside,
            BAvatar,
            BMediaBody,
            BCardText,
            BCardBody,
        },
        directives: {
            Ripple,
        },
        props: {
            item: {}
        },
        emits: ['statusKelompok','detail', 'loadEdit'],
        methods: {
            status_kelompok(value) {
                this.$emit("statusKelompok", value)
            },
            detail(value){ 
                this.$emit("detail", value)
            },
            loadEdit(value){
                this.$emit("detail", value)
            }
        }
    }
</script>