<template>
    <div>
        <div class="card">
            <div class="  statistics-body">
                <div class="row p-1">
                    <div class="col-xl-4 col-sm-6 col-12 ">
                        <div class="media">
                            <b-avatar size="50px" variant="light-danger"   >
                                <feather-icon icon="GridIcon" size="20" />
                            </b-avatar> 
                            <div class="media-body my-auto ml-1">
                                <h4 class="font-weight-bolder mb-0">{{ statistik.jm_kelompok}}</h4>
                                <p class="card-text font-small-3 mb-0">Total Kelompok Aktif</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-sm-6 col-12 ">
                        <div class="media">
                            <b-avatar size="50px" variant="light-info"   >
                                <feather-icon icon="UsersIcon" size="20" />
                            </b-avatar>  
                            <div class="media-body my-auto ml-1">
                                <h4 class="font-weight-bolder mb-0">{{ statistik.jm_anggota}}</h4>
                                <p class="card-text font-small-3 mb-0">User Pengguna</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-sm-0">
                        <div class="media">
                            <b-avatar size="50px" variant="light-success"   >
                                <feather-icon icon="MapPinIcon" size="20" />
                            </b-avatar>   
                            <div class="media-body my-auto ml-1">
                                <h4 class="font-weight-bolder mb-0">{{ statistik.jm_kordinat}}</h4>
                                <p class="card-text font-small-3 mb-0">Koordinat Aktif</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {
        BAvatar
    } from 'bootstrap-vue'

    export default {
        components: {
            BAvatar
        },
        props :{
            statistik : {}
        }
    }
</script>

<style>

</style>