<template>
  <div>
    <!-- basic modal -->
    <b-modal id="modal-1" :title="detailKelompok.nama_kelompok" ok-only ok-title="Keluar" hide-footer>

      <div>
        <div>
          <div>
            <h5>Bidang : {{ detailKelompok.id_bidang.nama_bidang}}</h5>
            <b> Durasi kerja
              {{ detailKelompok.durasi_kerja }} Jam</b><br>
            <small>
              <b-avatar size="20px" variant="primary" rounded="">
                <feather-icon icon="ClockIcon" size="15" />

              </b-avatar> {{ detailKelompok.jam_masuk }} - {{ detailKelompok.jam_pulang }}
            </small><br>
            <h6 class="mt-1"> Kordinator </h6>
            <b-avatar size="20px" variant="primary" rounded="">
              <feather-icon icon="UserIcon" size="15" />

            </b-avatar> {{ detailKelompok.nm_kordinator }}<br>
            <b-avatar size="20px" variant="primary" rounded="">
              <feather-icon icon="PhoneIcon" size="15" />

            </b-avatar> {{ detailKelompok.no_hp }} <br>
            <!-- <b class="text-danger" v-show=" item.status_klp !='Aktif'"> Status kelompok
                            {{ item.status_klp }}</b> -->
          </div>
        </div>
      </div>

      <b-card-text>
        <h6 class="mt-2">Maksimum Keterlambatan Presensi Masuk : {{ detailKelompok.max_terlambat }} Menit</h6>
        <p><b>Keterangan : </b><br> {{ detailKelompok.keterangan }}</p>

      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
  import {
    BModal,
    BButton,
    VBModal,
    BAlert,
    BCardText,
    BAvatar
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'

  export default {
    components: {
      BButton,
      BModal,
      BAlert,
      BCardText,
      BAvatar
    },
    directives: {
      'b-modal': VBModal,
      Ripple,
    },
    props: {
      detailKelompok: {}
    }
  }
</script>