<template>
    <div>
        <!-- basic modal -->
        <b-modal id="editKelompok" title="Edit Kelompok" size="lg" hide-footer no-close-on-backdrop
            content-class="shadow" ok-title="Keluar">
            <div>
                <validation-observer ref="simpleRules">
                    <b-form>
                        <b-row>
                            <b-col md="12">
                                <b-form-group>
                                    <label>Nama Kelompok </label>
                                    <validation-provider #default="{ errors }" name="nama_kelompok"   rules="required">
                                        <b-form-input v-model="inputData.nama_kelompok"
                                            :state="errors.length > 0 ? false:null" placeholder="Nama Kelompok" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <label>Bidang</label>
                                <b-form-group>
                                    <validation-provider #default="{ errors }" name="Bidang"  >
                                        <v-select rules="required" :state="errors.length > 0 ? false:null"
                                            v-model="inputData.id_bidang"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_bidang"
                                            :options="bidang" />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group>
                                    <label>Nama Kordinator </label>
                                    <validation-provider #default="{ errors }" name="nama_kordinator"   rules="required">
                                        <b-form-input v-model="inputData.nm_kordinator"
                                            :state="errors.length > 0 ? false:null" placeholder="Nama Kelompok" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group>
                                    <label>Cp Kordinator </label>
                                    <validation-provider #default="{ errors }" name="cp_kordinator"   rules="required">
                                        <b-form-input v-model="inputData.no_hp" :state="errors.length > 0 ? false:null"
                                            placeholder="Nama Kelompok" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-form-group>
                                    <label>Status Jam Kerja </label>
                                    <div class="demo-inline-spacing">
                                        <b-form-radio v-model="inputData.jam_kerja" name="some-radios"
                                            value="fleksibel">
                                            Mengacu Durasi Jam Kerja
                                        </b-form-radio>
                                        <b-form-radio v-model="inputData.jam_kerja" name="some-radios"
                                            value="sesuai_jam_kerja">
                                            Ditentukan jam Masuk dan Pulang
                                        </b-form-radio>
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6" v-if="inputData.jam_kerja =='sesuai_jam_kerja'">
                                <b-form-group>
                                    <label>Jam Masuk </label>
                                    <validation-provider #default="{ errors }" name="Jam_masuk"   rules="required">
                                        <flat-pickr v-model="inputData.jam_masuk" class="form-control"
                                            :state="errors.length > 0 ? false:null"
                                            :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i:S', time_24hr: true,}" />
                                        <small class="text-danger">{{ errors[0] }}</small></validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6" v-if="inputData.jam_kerja =='sesuai_jam_kerja'">
                                <b-form-group>
                                    <label>Jam Pulang </label>
                                    <validation-provider #default="{ errors }" name="jam_pulang"   rules="required">
                                        <flat-pickr v-model="inputData.jam_pulang" class="form-control"
                                            :state="errors.length > 0 ? false:null"
                                            :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i:S', time_24hr: true,}" />
                                        <small class="text-danger">{{ errors[0] }}</small></validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6" v-if="inputData.jam_kerja =='sesuai_jam_kerja'">
                                <b-form-group>
                                    <label>Toleransi Keterlambatan (*Menit) </label>
                                    <validation-provider #default="{ errors }" name="Toleransi_tketerlambatan"  rules="required"> 
                                        <b-form-input v-model="inputData.max_terlambat" type="number"
                                            :state="errors.length > 0 ? false:null" placeholder="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group>
                                    <label>Durasi Jam Kerja </label>
                                    <validation-provider #default="{ errors }" name="durasi_jam_kerja"  rules="required">
                                        <b-form-input v-model="inputData.durasi_kerja" type="number" 
                                            :state="errors.length > 0 ? false:null" placeholder="Nama Kelompok" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12">
                                <label>Keterangan</label>
                                <b-form-group>
                                    <validation-provider #default="{ errors }" name="Keterangan">
                                        <b-form-textarea :state="errors.length > 0 ? false:null"
                                            v-model="inputData.keterangan"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" id="textarea-default"
                                            placeholder="Textarea" rows="3" />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row class="mb-5">
                            <b-col md="6">
                                <b-button      @click="simpanKelomok()"
                                variant="outline-primary">
                                    <feather-icon icon="SaveIcon" size="15" />  Update
                                </b-button>
                                <b-button     class="ml-1" @click="Batalkan()"
                                variant="outline-warning">
                                    <feather-icon icon="XCircleIcon" size="15" /> Batalkan
                                </b-button>
                            </b-col>
                            
                        </b-row>
                    </b-form>
                </validation-observer>

            </div>
        </b-modal>
    </div>
</template>

<script>
    import axios from '@/config/Axios';
    import Base from '@/config/Mixins_base';
    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import {
        required
    } from '@validations'

    import vSelect from 'vue-select'


    import {
        BModal,
        BButton,
        VBModal,
        BAlert,
        BCardText,
        BAvatar,
        BForm,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BTime, BFormRadio
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import flatPickr from 'vue-flatpickr-component'

    export default {
        components: {
            BButton,
            BModal,
            BAlert,
            BCardText,
            BAvatar,
            vSelect,
            ValidationProvider,
            ValidationObserver,
            BForm,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormTextarea,
            BTime,
            flatPickr,BFormRadio
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        props: {
            detailKelompok: {},
            bidang: {},
            inputData : {}
        },
        mixins: [Base],
        emits :["editKelompok"],
        data() {
            return {
                required,
                inputData: {},
                timePicker: null,
            }
        }, 
        methods: {
            onContext(ctx) {
                this.context = ctx
            },
            simpanKelomok() {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.$root.$emit('bv::hide::modal', 'editKelompok')
                        this.$emit("editKelompok", this.inputData)
                        this.inputData = {}
                    }else{
                         
                    }
                })
            },
            Batalkan(){
                this.$root.$emit('bv::hide::modal', 'editKelompok')
            }
        }
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>