var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"tambahKelompok","title":"Tambah Kelompok","size":"lg","hide-footer":"","no-close-on-backdrop":"","content-class":"shadow","ok-title":"Keluar"}},[_c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Nama Kelompok ")]),_c('validation-provider',{attrs:{"name":"nama_kelompok","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Nama Kelompok"},model:{value:(_vm.inputData.nama_kelompok),callback:function ($$v) {_vm.$set(_vm.inputData, "nama_kelompok", $$v)},expression:"inputData.nama_kelompok"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v("Bidang")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Bidang"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"rules":"required","state":errors.length > 0 ? false:null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nama_bidang","options":_vm.bidang},model:{value:(_vm.inputData.id_bidang),callback:function ($$v) {_vm.$set(_vm.inputData, "id_bidang", $$v)},expression:"inputData.id_bidang"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Nama Kordinator ")]),_c('validation-provider',{attrs:{"name":"nama_kordinator","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Nama Kelompok"},model:{value:(_vm.inputData.nm_kordinator),callback:function ($$v) {_vm.$set(_vm.inputData, "nm_kordinator", $$v)},expression:"inputData.nm_kordinator"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Cp Kordinator ")]),_c('validation-provider',{attrs:{"name":"cp_kordinator","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Nama Kelompok"},model:{value:(_vm.inputData.no_hp),callback:function ($$v) {_vm.$set(_vm.inputData, "no_hp", $$v)},expression:"inputData.no_hp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Status Jam Kerja ")]),_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-radio',{attrs:{"name":"some-radios","value":"fleksibel"},model:{value:(_vm.inputData.jam_kerja),callback:function ($$v) {_vm.$set(_vm.inputData, "jam_kerja", $$v)},expression:"inputData.jam_kerja"}},[_vm._v(" Mengacu Durasi Jam Kerja ")]),_c('b-form-radio',{attrs:{"name":"some-radios","value":"sesuai_jam_kerja"},model:{value:(_vm.inputData.jam_kerja),callback:function ($$v) {_vm.$set(_vm.inputData, "jam_kerja", $$v)},expression:"inputData.jam_kerja"}},[_vm._v(" Ditentukan jam Masuk dan Pulang ")])],1)])],1),(_vm.inputData.jam_kerja =='sesuai_jam_kerja')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Jam Masuk ")]),_c('validation-provider',{attrs:{"name":"Jam_masuk","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false:null,"config":{ enableTime: true, noCalendar: true, dateFormat: 'H:i:S', time_24hr: true,}},model:{value:(_vm.inputData.jam_masuk),callback:function ($$v) {_vm.$set(_vm.inputData, "jam_masuk", $$v)},expression:"inputData.jam_masuk"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2891937601)})],1)],1):_vm._e(),(_vm.inputData.jam_kerja =='sesuai_jam_kerja')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Jam Pulang ")]),_c('validation-provider',{attrs:{"name":"jam_pulang","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false:null,"config":{ enableTime: true, noCalendar: true, dateFormat: 'H:i:S', time_24hr: true,}},model:{value:(_vm.inputData.jam_pulang),callback:function ($$v) {_vm.$set(_vm.inputData, "jam_pulang", $$v)},expression:"inputData.jam_pulang"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1696805505)})],1)],1):_vm._e(),(_vm.inputData.jam_kerja =='sesuai_jam_kerja')?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Toleransi Keterlambatan (*Menit) ")]),_c('validation-provider',{attrs:{"name":"Toleransi_tketerlambatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.inputData.max_terlambat),callback:function ($$v) {_vm.$set(_vm.inputData, "max_terlambat", $$v)},expression:"inputData.max_terlambat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2969635593)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Durasi Jam Kerja ")]),_c('validation-provider',{attrs:{"name":"durasi_jam_kerja","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null,"placeholder":"Nama Kelompok"},model:{value:(_vm.inputData.durasi_kerja),callback:function ($$v) {_vm.$set(_vm.inputData, "durasi_kerja", $$v)},expression:"inputData.durasi_kerja"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v("Keterangan")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Keterangan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"id":"textarea-default","placeholder":"Textarea","rows":"3"},model:{value:(_vm.inputData.keterangan),callback:function ($$v) {_vm.$set(_vm.inputData, "keterangan", $$v)},expression:"inputData.keterangan"}})]}}])})],1)],1)],1),_c('hr'),_c('b-row',{staticClass:"mb-5"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.simpanKelomok()}}},[_c('feather-icon',{attrs:{"icon":"SaveIcon","size":"15"}}),_vm._v(" Simpan ")],1),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"outline-warning"},on:{"click":function($event){return _vm.Batalkan()}}},[_c('feather-icon',{attrs:{"icon":"XCircleIcon","size":"15"}}),_vm._v(" Batalkan ")],1)],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }