<template>
  <div>
    <b-row>
      <b-col md="10">
        <statistik :statistik=statistik></statistik>
      </b-col>
      <b-col md="2">
        <a>
          <b-button v-b-modal.tambahKelompok block variant="primary">
            <feather-icon icon="FolderPlusIcon" size="25" class="mb-1" /> <br>Add Kelompok
          </b-button>
        </a>
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col md="6">
        <b-form-group>
          <v-select @input="load_data()"  
          v-model="id_bidang" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_bidang"
            :options="bidang" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <!-- button on right -->
        <b-input-group>
          <b-form-input placeholder="input pencarian" v-model="cari" @input="cariData()" />
          <b-input-group-append>
            <b-button variant="outline-primary">
              <feather-icon icon="SearchIcon" /> Search
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3" v-for="item in items.rs" v-bind:key="item.id_kelompok">
        <card :item="item" @statusKelompok=statusKelompok @detail=detail></card>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="6">
        Total Row {{ items.lenght}}
      </b-col>
      <b-col md="6">
        <b-pagination v-model="currentPage" hide-goto-end-buttons :per-page='4' :total-rows="items.lenght" align="right"
          @input="pageNext(currentPage)" />
      </b-col>
    </b-row>
    <detailVue :detailKelompok=detailKelompok></detailVue>
    <add :bidang=bidang @simpan_kelompok=simpan_kelompok></add>
    <edit :inputData=inputData @editKelompok=editKelompok></edit>
  </div>
</template>

<script>
  import axios from '@/config/Axios';
  import base from '@/config/Mixins_base'
  import statistik from './statistik.vue';
  import card from './card.vue';
  import edit from './edit.vue';
  import {
    BRow,
    BCol,
    BButton,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BPagination,
    BModal,
    VBModal,
    BAlert
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import Ripple from 'vue-ripple-directive'
  import detailVue from './detail.vue';
  import add from './add.vue';
  export default {
    components: {
      card,
      BRow,
      BCol,
      BButton,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BInputGroupPrepend,
      BPagination,
      statistik,
      BModal,
      VBModal,
      BAlert,
      detailVue,
      add,
      edit,
      vSelect
    },
    data() {
      return {
        currentPage: 1,
        perPage: 3,
        rows: 0,
        cari: '',
        offset: '',
        items: [],
        detailKelompok: {},
        bidang: [],
        inputData: {},
        statistik: {},
        id_bidang: {}
      }
    },
    mixins: [base],
    mounted() {
      this.cek_token();
      this.load_data();
      this.load_bidang();
    },
    methods: {
      cariData() {
        this.load_data()
      },
      detail(value) {
        this.get_edit(value.id_kelompok);
      },
      async load_statistik() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/kelompok/load_statistik',
            data: {

            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.statistik = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async get_edit(id_kelompok) {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/kelompok/get_edit',
            data: {
              id_kelompok: id_kelompok
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.detailKelompok = response.data.result;
            self.inputData = response.data.result;
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      }, 
      async load_data() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/kelompok/load_data',
            data: {
              cari: self.cari,
              offset: self.currentPage, 
              id_bidang : self.id_bidang.id_bidang
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.items = response.data.result;
            self.load_statistik();

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },

      async load_bidang() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/kelompok/bidang',
            data: {},
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.bidang = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },

      async update_status(id_kelompok) {
        const self = this;
        await axios({
            method: 'POST',
            url: '/api/kelompok/status',
            data: {
              id_kelompok: id_kelompok
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.notification('info', "", response.data.message);
            self.load_data();

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },

      async simpan_kelompok(value) {
        const self = this;
        await axios({
            method: 'POST',
            url: '/api/kelompok/insert',
            data: {
              jam_kerja: value.jam_kerja,
              id_bidang: value.id_bidang.id_bidang,
              nama_kelompok: value.nama_kelompok,
              keterangan: value.keterangan,
              nm_kordinator: value.nm_kordinator,
              no_hp: value.no_hp,
              jam_masuk: value.jam_masuk,
              jam_pulang: value.jam_pulang,
              max_terlambat: value.max_terlambat,
              durasi_kerja: value.durasi_kerja,
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.notification('info', "", response.data.message);
            self.load_data();

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async editKelompok(value) {
        const self = this;
        await axios({
            method: 'POST',
            url: '/api/kelompok/update',
            data: {
              id_kelompok: value.id_kelompok,
              id_bidang: value.id_bidang.id_bidang,
              nama_kelompok: value.nama_kelompok,
              keterangan: value.keterangan,
              nm_kordinator: value.nm_kordinator,
              no_hp: value.no_hp,
              jam_masuk: value.jam_masuk,
              jam_pulang: value.jam_pulang,
              max_terlambat: value.max_terlambat,
              durasi_kerja: value.durasi_kerja,
              jam_kerja: value.jam_kerja,
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.notification('info', "", response.data.message);
            self.load_data();

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      statusKelompok(value) {
        if (confirm("Apakah anda yakin akan mengupdate status kelompok ini..?") == true) {
          this.update_status(value.id_kelompok)
        }
      },
      pageNext() {
        this.load_data();
      }
    },
    directives: {
      'b-modal': VBModal,
      Ripple,
    },

  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>